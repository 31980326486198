import { cn } from "@/libs";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<HTMLElement> {
  translatableText: string;
  fragment?: boolean;
}

const TranslatableText = ({
  translatableText,
  className,
  fragment = false,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {fragment ? (
        <>{t(translatableText)}</>
      ) : (
        <p
          className={cn(" text-primary-dark-gray capitalize", className)}
          {...props}
        >
          {t(translatableText)}
        </p>
      )}
    </>
  );
};

export default TranslatableText;
