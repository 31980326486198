import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "leaflet/dist/leaflet.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import "./i18n.ts";
import { FavoritesProvider } from "./providers/FavoritesProvider";
import "./index.css";
import CurrentCurrencyProvider from "./providers/current-currency-provider/current-currency-provider.tsx";
import LanguageProvider from "./providers/language-provider/language-provider.tsx";

const client = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <CurrentCurrencyProvider>
      <LanguageProvider>
        <QueryClientProvider client={client}>
          <FavoritesProvider>
            <App />
          </FavoritesProvider>
        </QueryClientProvider>
      </LanguageProvider>
    </CurrentCurrencyProvider>
  </BrowserRouter>
);
