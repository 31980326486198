import type { UseLocalStorageType } from "./types";

const useLocalStorage = ({ key, method, value }: UseLocalStorageType) => {
  if (method === "set" && value) {
    localStorage.setItem(key, value);
  } else if (method === "get") {
    return localStorage.getItem(key);
  } else {
    localStorage.removeItem(key);
  }
};

export default useLocalStorage;
