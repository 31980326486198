import { useLocalStorage } from "@/hooks";
import {
  ChangeEvent,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface CurrentPriceContextProps {
  curr: "AMD" | "USD" | "EUR" | "RUB";
  currObj: CurrentPriceProps | undefined;
  changeCurrencyValue: ({ target }: ChangeEvent<HTMLSelectElement>) => void;
  setCurrencyHandler: (currency: CurrentPriceContextProps["curr"]) => void;
}

interface CurrentPriceProps {
  AMD: number;
  EUR: number;
  USD: number;
  RUB: number;
}

export const CurrentPriceContext = createContext<CurrentPriceContextProps>({
  curr: "USD",
  currObj: undefined,
  changeCurrencyValue: () => {},
  setCurrencyHandler: () => {},
});

const CurrentCurrencyProvider = ({ children }: { children: ReactNode }) => {
  const [curr, setCurr] = useState<CurrentPriceContextProps["curr"]>("USD");
  const [currObj, setCurrobj] = useState<CurrentPriceProps | undefined>(
    undefined
  );
  const currentPrice = useLocalStorage({ key: "currency", method: "get" });

  useEffect(() => {
    fetch(`https://new.yerevanhouse.net/api/currency`)
      .then((res) => res.json())
      .then((res) => setCurrobj(res));

    setCurr((currentPrice || "USD") as CurrentPriceContextProps["curr"]);
  }, [currentPrice]);

  const setCurrencyHandler = (currency: CurrentPriceContextProps["curr"]) => {
    setCurr(currency);
  };

  const changeCurrencyValue = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = target.value;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLocalStorage({ key: "currency", value: newCurrency, method: "set" });
    setCurr(newCurrency as CurrentPriceContextProps["curr"]);
  };

  const values = useMemo<CurrentPriceContextProps>(() => {
    return { curr, currObj, setCurrencyHandler, changeCurrencyValue };
  }, [curr, currObj]);

  return (
    <CurrentPriceContext.Provider value={values}>
      {children}
    </CurrentPriceContext.Provider>
  );
};

export default CurrentCurrencyProvider;
