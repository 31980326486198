import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import EnTranslations from "@/translations/en.json";
import AmTranslations from "@/translations/am.json";
import RuTranslations from "@/translations/ru.json";

const resources = {
  am: {
    translation: AmTranslations,
  },
  en: {
    translation: EnTranslations,
  },
  ru: {
    translation: RuTranslations,
  },
};

void i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: "am",
  fallbackLng: "am",
});

export default i18n;
