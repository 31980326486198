import { PropagateLoader } from "react-spinners";
import TranslatableText from "../TranslatableText/TranslatableText";

const Loading = () => {
  return (
    <div className=" w-full h-fit flex items-center flex-col gap-6 animate-pulse">
      <TranslatableText
        translatableText="loading"
        className=" text-primary-blue text-xl font-bold"
      />
      <PropagateLoader color="#22668D" />
    </div>
  );
};

export default Loading;
