import { FavoritesProps } from "@/providers/FavoritesProvider/FavoritesProvider";
import clsx from "clsx";
import { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...input: ClassValue[]) => {
  return twMerge(clsx(...input));
};

export const calculateCount = (value: FavoritesProps) => {
  return Object.values(value || {}).reduce((total, items) => total + items.length, 0) || 0;
};
