import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const RootLayout = lazy(() => import("@/layouts/root-layout"));
const Home = lazy(() => import("@/pages/home/home"));
const Sale = lazy(() => import("@/pages/sale/sale"));
const SaleProduct = lazy(() => import("@/pages/sale-product/sale-product"));
const Rent = lazy(() => import("@/pages/rent/rent"));
const Cooperate = lazy(() => import("@/pages/cooperate/cooperate"));
const Map = lazy(() => import("@/pages/map/map"));
const Urgently = lazy(() => import("@/pages/urgently/urgently"));
const Favorites = lazy(() => import("@/pages/Favorites/Favorites"));
const RentProduct = lazy(() => import("@/pages/rent-single-product"));
const Success = lazy(() => import("@/pages/Success/Success"));
const Cancel = lazy(() => import("@/pages/Cancel/Cancel"));
const AboutCountry = lazy(() => import("@/pages/SingleAboutCountry/AboutCountry"));
const SingleAboutCountry = lazy(() => import("@/pages/SingleAboutCountry/SingleAboutCountry"));

const Router = () => {
  return (
    <Routes>
      <Route path="/" Component={RootLayout}>
        <Route index Component={Home} />
        <Route path="sale" Component={Sale} />
        <Route path="sale/:subsection/:id" Component={SaleProduct} />
        <Route path="rent" Component={Rent} />
        <Route path="rent/:subsection/:id/:rentType" Component={RentProduct} />
        <Route path="cooperation" Component={Cooperate} />
        <Route path="urgently" Component={Urgently} />
        <Route path="map" Component={Map} />
        <Route path="favorites" Component={Favorites} />
        <Route path="success" Component={Success} />
        <Route path="fail" Component={Cancel} />
        <Route path="about-country" Component={AboutCountry} />
        <Route path="about-country/:id" Component={SingleAboutCountry} />
      </Route>
    </Routes>
  );
};

export default Router;
