import Loading from "./loading";

const FallbackLoading = () => {
  return (
    <section className=" w-full h-screen flex items-center justify-center flex-col gap-6 bg-primary-light">
      <div className=" p-6 bg-primary-blue shadow-primary-shadow rounded-xl animate-pulse">
        <img src="/images/logo.png" alt="logo" width={120} height={120} />
      </div>
      <p className=" uppercase text-primary-dark-gray/90 font-semibold md:text-4xl text-3xl tracking-widest animate-pulse">
        Yerevan House
      </p>
      <Loading />
    </section>
  );
};

export default FallbackLoading;
