import { ReactNode, createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { LanguageProps } from "./types";
import { useLocalStorage } from "@/hooks";
import { useCurrentCurrency } from "../current-currency-provider/hooks";

const defaultValues = {
  currentLang: "en",
  handleSwitchLanguages: () => {},
};

export const LanguageContext = createContext<LanguageProps>(defaultValues);

interface LanguageProviderProps {
  children: ReactNode;
}

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [currentLang, setCurrentLang] = useState<string>("am"); // Default language
  const { i18n } = useTranslation();
  const { setCurrencyHandler } = useCurrentCurrency();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const storedLang = useLocalStorage({ key: "lang", method: "get" });
    if (storedLang) {
      i18n.changeLanguage(storedLang);
      setCurrentLang(storedLang);
    }
  }, [currentLang, i18n]);

  const handleSwitchLanguages = (lang: string) => {
    setCurrentLang(lang);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLocalStorage({ key: "lang", value: lang, method: "set" });
    i18n.changeLanguage(currentLang);
    if (lang === "am") {
      setCurrencyHandler("AMD");
      useLocalStorage({ method: "set", key: "currency", value: "AMD" });
    } else if (lang === "ru") {
      useLocalStorage({ method: "set", key: "currency", value: "RUB" });
      setCurrencyHandler("RUB");
    } else if (lang === "en") {
      useLocalStorage({ method: "set", key: "currency", value: "USD" || "EUR" });
      setCurrencyHandler("USD" || "EUR");
    } else {
      useLocalStorage({ method: "set", key: "currency", value: "USD" });
      setCurrencyHandler("USD");
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        currentLang,
        handleSwitchLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
