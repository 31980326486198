import { ErrorInfo, FC, Suspense } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import Router from "./router";
import { FallbackLoading } from "./components/ui/loadings";

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <div>
    <h2>Something went wrong:</h2>
    <pre>{error.message}</pre>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);

const App = () => {
  const logError = (error: Error, info: ErrorInfo) => {
    console.log(JSON.stringify(error, null, 2), "<<<<<<<<<<< error");
    console.log(JSON.stringify(info, null, 2), "<<<<<<<<<<<<<");
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <Suspense fallback={<FallbackLoading />}>
        <Router />
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
