import { useContext } from "react";
import {
  CurrentPriceContext,
  CurrentPriceContextProps,
} from "../current-currency-provider";

const useCurrentCurrency = (): CurrentPriceContextProps =>
  useContext(CurrentPriceContext);

export default useCurrentCurrency;
