import { useLocalStorage } from "@/hooks";
import { ReactNode, createContext, useEffect, useMemo, useState } from "react";

export interface FavoritesProps {
  [key: string]: number[];
}

export interface FavoritesContextProps {
  favorites: FavoritesProps;
  toggleToFavorites: ({ id, type }: { id: number; type: string }) => void;
}

export const FavoritesContext = createContext<FavoritesContextProps>({
  favorites: {},
  toggleToFavorites: () => {},
});

interface FavoritesProviderProps {
  children: ReactNode;
}

const initialFavorites = {
  flat: [],
  house: [],
  landareas: [],
  commercial: [],
};

const FavoritesProvider = ({ children }: FavoritesProviderProps) => {
  const [favorites, setFavorites] = useState<FavoritesContextProps["favorites"]>(initialFavorites);

  const toggleToFavorites = ({ id, type }: { id: number; type: string }) => {
    // Retrieve stored favorites
    const storedFavorites = useLocalStorage({ key: "favorites", method: "get" });

    // If storedFavorites is null or id or type is not provided, return
    if (!storedFavorites || !id || !type) return;

    // Parse stored favorites
    const parsedFavorites: FavoritesProps = JSON.parse(storedFavorites);

    // If the type doesn't exist in favorites, initialize it with an empty array
    if (!parsedFavorites[type]) {
      parsedFavorites[type] = [];
    }

    // Check if id is already in favorites
    const isFavorite = parsedFavorites[type].includes(id);

    // Toggle id in favorites
    if (!isFavorite) {
      parsedFavorites[type].push(id);
    } else {
      const indexOfFavorite = parsedFavorites[type].indexOf(id);
      if (indexOfFavorite !== -1) {
        parsedFavorites[type].splice(indexOfFavorite, 1);
      }
    }

    // Update stored favorites
    useLocalStorage({
      key: "favorites",
      method: "set",
      value: JSON.stringify(parsedFavorites),
    });
    setFavorites(parsedFavorites);
  };

  useEffect(() => {
    const storedFavorites = useLocalStorage({ key: "favorites", method: "get" });

    // If storedFavorites is null or empty, set initialFavorites to local storage
    if (!storedFavorites || Object.keys(JSON.parse(storedFavorites)).length === 0) {
      localStorage.setItem("favorites", JSON.stringify(initialFavorites));
      setFavorites(initialFavorites);
    } else {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, []);

  const values = useMemo<FavoritesContextProps>(() => {
    return { favorites, toggleToFavorites };
  }, [favorites]);

  return <FavoritesContext.Provider value={values}>{children}</FavoritesContext.Provider>;
};

export default FavoritesProvider;
